<template>
  <!-- 服务协议管理 -->
  <div class="containerbox serverContent">
    <div class="formcontent">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="协议类型">
          <el-input v-model="formData.agreementType" disabled></el-input>
        </el-form-item>
        <el-form-item label="协议内容">
          <div id="eidtContent"></div>
        </el-form-item>
        <el-form-item>
          <el-button @click="gobackPage()">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
// import paging from "@/components/pagings.vue";
import E from "wangeditor";

export default {
  inject: ["reload"],

  data() {
    return {
      editor: {},
      formData: {},
     
    };
  },
  components: {
    // paging,
  },
  updated() {},
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  mounted() {
    this.initE();
  },
  watch: {},
  methods: {
    //   获取详情内容
    getDetail() {
      let querydata = {};
      querydata.configAgreementId = this.$route.query.id;

      Api.configAgreementDetail(querydata).then((res) => {
        if (res.data.status == "success") {
          console.log(res.data.result);
          this.formData = res.data.result;
          this.editor.txt.html(res.data.result.agreementContent);
        }
      });
    },
    gobackPage() {
      this.$router.go(-1);
    },
    //   初始化富文本编辑器
    initE() {
      this.editor = new E("#eidtContent");
      this.editor.config.height = 500;

      //   this.editor.config.uploadImgServer = "https://dev.b9.api.8bami.com/base/saas/v1/file/upload";
      // 编辑器菜单栏配置
      this.editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "indent", //缩进
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "table", // 表格
        "code", // 插入代码
        "undo", // 撤销
        "redo", // 重复
      ];

      // 自定义上传图片
      //   this.editor.config.customUploadImg = function(resultFiles, insertImgFn) {
      //     // resultFiles 是 input 中选中的文件列表
      //     // insertImgFn 是获取图片 url 后，插入到编辑器的方法

      //     // 上传图片，返回结果，将图片插入到编辑器中
      //     insertImgFn(imgUrl);
      //   };

      //这里各位小伙伴可以查询官网，根据自己的需求进行菜单栏调整
      this.editor.create();
      this.editor.disable();
    },

  
  },
};
</script>

<style scoped lang="scss">
.formcontent {
  max-width: 800px;
}
.containerbox {
  // height: calc(100vh - 61px);
  height: 100vh;
  padding: 16px;
}

.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.imgbox {
  width: 100%;
  margin-left: 12%;
  box-sizing: border-box;
}
.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
  }
}
.singleinp1 {
  min-width: 392px;
}
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}
.SingleChoicebox {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
}
.el-input-number {
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.serverContent {
  display: flex;
  justify-content: center;
  .tableBox {
    background-color: #fff;
  }
  .btnList1 {
    width: auto;
    padding: 10px;
    border-radius: 4px;
  }
}
</style>
